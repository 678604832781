import { fetch } from '../utils/fetch'
import NP from 'number-precision'

export async function GetSeckillStock(opt) {
  return await fetch('/service/interface/GetSeckillStock.do', opt)
}

export async function getSeckillBeginTime(opt) {
  return await fetch('/service/interface/v5/GetSeckillBeginTime.do', opt)
}

export function generateOrder(opt) {
  return fetch('/service/interface/v4/GenerateOrder.do', opt)
}

export function getGroupList(opt) {
  return fetch('/aomi-purchase/purchase/fen/list/search.do', opt)
  .then(res => {
    if (!res) return res
    if(res.detailMsg.groupList) {
      res.detailMsg.groupList.forEach((e) => {
        e.groupPrice = NP.divide(e.groupPrice || 0, 100)
        e.oldPrice = NP.divide(e.oldPrice || 0, 100)
        e.activityPrice = NP.divide(e.activityPrice || 0, 100)
      })
    }
    return res
  })
}

export function getGroupDetails(opt) {
  return fetch('/aomi-purchase/purchase/fen/v5/GetGroupPurchaseInfo.do', opt)
  .then(res => {
    if (!res) return res
    if(res.detailMsg.adultPrice) res.detailMsg.adultPrice = NP.divide(res.detailMsg.adultPrice, 100)
    if(res.detailMsg.childPrice) res.detailMsg.childPrice = NP.divide(res.detailMsg.childPrice, 100)
    if(res.detailMsg.groupPrice) res.detailMsg.groupPrice = NP.divide(res.detailMsg.groupPrice, 100)
    if(res.detailMsg.nextLevelPrice) res.detailMsg.nextLevelPrice = NP.divide(res.detailMsg.nextLevelPrice, 100)
    if(res.detailMsg.oldPrice) res.detailMsg.oldPrice = NP.divide(res.detailMsg.oldPrice, 100)
    if(res.detailMsg.activityPrice) res.detailMsg.activityPrice = NP.divide(res.detailMsg.activityPrice, 100)
    if(res.detailMsg.frontLevelPrice) res.detailMsg.frontLevelPrice = NP.divide(res.detailMsg.frontLevelPrice, 100)
    if(res.detailMsg.dishList){
      res.detailMsg.dishList.forEach((e) => {
        if(e.dishesDetail){
          e.dishesDetail.forEach((child)=>{
            child.dishPrice = NP.divide(child.dishPrice || 0, 100)
          })
        }
      })
    }
    if(res.detailMsg.otherGroup){
      res.detailMsg.otherGroup.forEach((e) => {
        e.oldPrice = NP.divide(e.oldPrice, 100)
        e.showPrice = NP.divide(e.showPrice, 100)
      })
    }
    if(res.detailMsg.recommedList){
      res.detailMsg.recommedList.forEach((e) => {
        e.groupPrice = NP.divide(e.groupPrice, 100)
        e.oldPrice = NP.divide(e.oldPrice, 100)
        e.activityPrice = NP.divide(e.activityPrice, 100)
      })
    }
    if(res.detailMsg.bargainProduct){
      if(res.detailMsg.bargainProduct.price) res.detailMsg.bargainProduct.price = NP.divide(res.detailMsg.bargainProduct.price, 100)
      if(res.detailMsg.bargainProduct.originalPrice) res.detailMsg.bargainProduct.originalPrice = NP.divide(res.detailMsg.bargainProduct.originalPrice, 100)
      if(res.detailMsg.bargainProduct.settlePrice) res.detailMsg.bargainProduct.settlePrice = NP.divide(res.detailMsg.bargainProduct.settlePrice, 100)
    }
    return res
  })
}

export function getSeckillStock(opt) {
  return fetch('/service/interface/GetSeckillStock.do', opt)
}

export function getbuffetDetails(opt) {
  return fetch('/aomi-purchase/buffet/fen/buffetDetail.do', opt)
  .then(res => {
    if (!res) return res
    if(res.detailMsg.groupPrice) res.detailMsg.groupPrice = NP.divide(res.detailMsg.groupPrice, 100)
    if(res.detailMsg.oldPrice) res.detailMsg.oldPrice = NP.divide(res.detailMsg.oldPrice, 100)
    if(res.detailMsg.priceList) {
      res.detailMsg.priceList.forEach((e) => {
        if(e.dishesDetail){
          e.dishesDetail.forEach((child) => {
            child.dishPrice = NP.divide(child.dishPrice, 100)
          })
        }
      })
    }
    return res
  })
}
                  
// 這個接口用在首頁美食
export function getAllFoodType(opt) {
  return fetch('/aomi-base-info-api/store-clazz-info/v2/getStoreClazz')
}
// 這個接口用在首頁團購和自助餐，從底部欄進入的團購也是該接口
//
export function getGroupBuffetClazz(opt) {
  return fetch('/service/interface/getGroupBuffetClazz.do')
}

export function getAllBusinessCenter(opt) {
  return fetch('/aomi-base-info-api/business-center/getAllBusinessCenter', opt)
}

export function getRecommendStoreList(opt) {
  return fetch('/service/interface/v2/GetRecommendStoreList.do', opt)
}

export function getGroupBuffetData(opt) {
  return fetch('/service/interface/GetGroupBuffetData.do', opt)
}

export function getOrderList(opt) {
  return fetch('/service/interface/v3/GetOrderList.do', opt)
}
export function GetgroupSubfield(opt) {
  return fetch('/service/interface/v2/activity.do', opt)
}
export function GetgroupSubfield_V2(opt) {
  return fetch('/service/interface/groupPurchase/activity.do', opt)
}

export function getGroupEvaluateInfo(opt) {
  return fetch('/aomi-purchase/app/evaluate/review-list-view', opt)
}

export function getRichTextById({ id }) {
  return fetch(`/aomi-purchase/purchase/${id}/graphic-desc`, {})
}