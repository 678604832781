/**
 * Created by wfx on 2017/8/24.
 */
import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

Vue.use(Vuex)

const state = {
  indicator: {
    show: true,
    text: '加載中...'
  },
  searchValue: '',
  showSearchPage: false,
  user: null,
  groupPurchaseInfo: {},
  isWeiXin: true
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
