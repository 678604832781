import store from '@/store/'
import { ua } from '@/utils/util'

// 掛載user
export function setStore () {
  if (window.localStorage.user) {
    store.commit('SET_USER', JSON.parse(window.localStorage.user))
  }
  store.commit('SET_IS_WEIXIN', ua.isWeiXin()) // 清空頁面搜索框的值
}
