/**
 * Created by wfx on 2017/8/24.
 */
export const SHOW_INDCATOR = 'SHOW_INDCATOR'// 隱藏頁面切換提示toast
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH'// 切換顯示搜索頁面
export const SET_USER = 'SET_USER'// 設置用戶信息
export const DEL_USER = 'DEL_USER'// 删除用戶信息
export const GET_GROUP_DETAIL = 'GET_GROUP_DETAIL'// 設置用戶信息
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'// 設置搜索框的值
export const SET_IS_WEIXIN = 'SET_IS_WEIXIN'// 設置搜索框的值
