export default {
  home: {
    android: '',
    ios: ''
  },
  // 自助餐详情
  MLBuffetDetailVC: {
    android: 'grouppurchase.GroupPurchaseInfoActivity',
    ios: 'MLBuffetDetailVC'
  },
  // 工银联名卡
  MLICBCWebVC: {
    android: '', // h5链接
    ios: 'MLICBCWebVC'
  },
  // 切换到tabbar外卖列表
  MLWaiMaiListController: {
    android: 'home.HomeActivity',
    ios: 'MLWaiMaiListController'
  },
  // 外卖列表
  MLTHomeVC: {
    android: 'takeaway.list.TakeawayBigBangListActivity',
    ios: 'MLTHomeVC'
  },
  // 外卖点单页
  MLMakeOrderViewController: {
    android: 'takeaway.info.TakeawayStoreInfoActivity',
    ios: 'MLMakeOrderViewController'
  },
  // 商户详情
  MLCompleteInfoVC: {
    android: 'store.StoreInfoActivity',
    ios: 'MLCompleteInfoVC'
  },
  // 团购详情
  MLGroupDetailInfoVC: {
    android: 'grouppurchase.GroupPurchaseInfoActivity',
    ios: 'MLGroupDetailInfoVC'
  },
  // 优选详情
  MLPreferredDetailInfoVC: {
    android: 'preferred.info.PreferredInfoActivity',
    ios: 'MLPreferredDetailInfoVC'
  },
  // 团购/自助餐列表
  MLGroupBuyingListVC: {
    android: 'grouppurchase.GroupPurchaseListActivity',
    ios: 'MLGroupBuyingListVC'
  },
  // 优惠券
  CouponMainVC: {
    android: 'coupon.CouponTicketTabsActivity',
    ios: 'CouponMainVC'
  },
  // 领券中心
  TicketCenterController: {
    android: 'coupon.CouponTicketGetCenterActivity',
    ios: 'TicketCenterController'
  },
  // 发现列表
  MLFoundVC: {
    android: 'home.HomeActivity',
    ios: 'MLFoundVC'
  },
  // 我喜欢列表
  MLMyLikeListVC: {
    android: 'store.FavoriteStoreListActivity',
    ios: 'MLMyLikeListVC'
  }
}
