import Vue from 'vue'
import Router from 'vue-router'

const testRoute = () => import('../pages/testRoute')
const guideSignIn = () => import('../pages/guide/signIn')
const home = () => import('../pages/home')
const groupList = () => import('../pages/group/groupList')
const takeoutList = () => import('../pages/takeout/takeoutList')
const takeoutDetail = () => import('../pages/takeout/takeoutDetail')
const takeoutLbs = () => import('../pages/takeout/takeoutLbs')
const takeoutSubfield = () => import('../pages/takeout/takeoutSubfield')
const my = () => import('../pages/my/my')
const orderList = () => import('../pages/my/child/orderList')
const bufferDinnerOrder = () => import('../pages/my/child/bufferDinnerOrder')
const takeoutOrder = () => import('../pages/my/child/takeoutOrder')
const refundDetail = () => import('../pages/my/child/refundDetail')
const orderDetail = () => import('../pages/my/child/orderDetail')
const discount = () => import('../pages/my/child/discount')
const discountLose = () => import('../pages/my/child/discountLose')
const discountExplain = () => import('../pages/my/child/discountExplain')
const discountExplainSecond = () =>
  import('../pages/my/child/discountExplainSecond')
const agreement = () => import('../pages/my/child/agreement')
const myselfSeting = () => import('../pages/my/child/myselfSeting')
const myselfModif = () => import('../pages/my/child/myselfModif')
const userLoveStore = () => import('../pages/my/child/userLoveStore')
const discover = () => import('../pages/discover')
const secKillActivity = () => import('../pages/group/child/secKillActivity.vue')
const storeDetail = () => import('../pages/store/storeDetail')
const storeList = () => import('../pages/store/storeList')
const storeCommentsList = () => import('../pages/store/commentList')
const preferredOrder = () => import('../pages/my/child/preferredOrder')
const groupDetail = () => import('../pages/group/child/groupDetail')
const groupPicDetail = () => import('../pages/group/child/groupPicDetail')
const groupCommentsList = () => import('../pages/group/child/commentList')
const buffetDinner = () => import('../pages/buffetDinner/buffetDinner')
const buffetDinnerReserve = () =>
  import('../pages/buffetDinner/buffetDinnerReserve')
const buffetDinnerDetail = () =>
  import('../pages/buffetDinner/buffetDinnerDetail')
const buffetCommentsList = () => import('../pages/buffetDinner/commentList')
const delicacies = () => import('../pages/store/delicacies')
const shopping = () => import('../pages/store/shopping')
const newStore = () => import('../pages/takeout/newStore')
const onlineBuy = () => import('../pages/takeout/onlineBuy')
const buffetDinnerPicTextDetail = () =>
  import('../pages/buffetDinner/buffetDinnerPicTextDetail')
const buffetPaySuccess = () => import('../pages/buffetDinner/buffetPaySuccess')
const groupOrderCommit = () => import('../pages/group/child/groupOrderCommit')
const paySuccess = () => import('../pages/group/child/paySuccess')
const groupSubfield = () => import('../pages/group/child/groupSubfield')
const buffetDinnerCommit = () =>
  import('../pages/buffetDinner/buffetDinnerCommit.vue')
const login = () => import('../pages/login')
const searchPosition = () => import('../pages/searchPosition')
const preferredDetail = () => import('../pages/preferred/preferredDetail')
const preferredList = () => import('../pages/preferred/preferredList')
const cyberSource = () => import('../components/cyberSource.vue')
const Map = () => import('../components/Map.vue')
const takeoutSearch = () => import('../pages/takeout/takeoutSearch')
const addNewPosition = () => import('../pages/takeout/addNewPosition')
const GroupRefundDetail = () => import('../pages/my/child/GroupRefundDetail')
const goRefund = () => import('../pages/my/child/goRefund')
const inviteGetRedPacket = () => import('../pages/my/child/inviteGetRedPacket')
const takeoutAllClazz = () => import('../pages/takeout/takeoutAllClazz.vue')
const takeoutSubList = () => import('../pages/takeout/takeoutSubList.vue')
const newSubfield = () => import('../pages/group/child/newSubfield.vue')
const takeoutSearchResult = () =>
  import('../pages/takeout/takeoutSearchResult.vue')
const footballActive = () => import('../pages/group/child/footballActive.vue')
const football = () => import('../pages/group/child/football.vue')
const activity = () => import('../pages/activity/activity.vue')
const newSemester = () => import('../pages/activity/newSemester.vue')
Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/testRoute',
      name: 'testRoute',
      component: testRoute
    },
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
      path: '/guideSignIn',
      name: 'guideSignIn',
      component: guideSignIn,
      meta: {
        script: ['weixinSdk']
      }
    },
    {
      path: '/groupList',
      name: 'groupList',
      component: groupList,
      meta: {
        // bMap: true,
        keepAlive: true,
        script: ['weixinSdk', 'baiduMap']
      }
    },
    {
      path: '/takeoutList',
      name: 'takeoutList',
      component: takeoutList,
      meta: {
        // bMap: true,
        script: ['baiduMap'],
        keepAlive: true
      }
    },
    {
      path: '/takeoutSearchResult',
      name: 'takeoutSearchResult',
      component: takeoutSearchResult,
      meta: {
        // bMap: true,
        script: ['baiduMap'],
        keepAlive: true
      }
    },
    {
      path: '/addNewPosition',
      name: 'addNewPosition',
      component: addNewPosition,
      meta: {
        auth: true
      }
    },
    {
      path: '/takeoutLbs',
      name: 'takeoutLbs',
      component: takeoutLbs
    },
    {
      path: '/takeoutDetail',
      name: 'takeoutDetail',
      component: takeoutDetail,
      meta: {
        script: ['weixinSdk', 'baiduMap']
      }
    },
    {
      path: '/takeoutSubfield',
      name: 'takeoutSubfield',
      component: takeoutSubfield,
      meta: {
        // bMap: true
        script: ['baiduMap', 'weixinSdk']
      }
    },
    {
      path: '/discover',
      name: 'discover',
      component: discover
      // meta: {
      //   keepAlive: true
      // }
    },
    {
      path: '/my',
      name: 'my',
      component: my
    },
    {
      path: '/Map',
      name: 'Map',
      component: Map
    },
    {
      path: '/orderList',
      name: 'orderList',
      component: orderList,
      meta: {
        auth: true,
        keepAlive: true
      }
    },
    {
      path: '/bufferDinnerOrder',
      name: 'bufferDinnerOrder',
      component: bufferDinnerOrder,
      meta: {
        auth: true
      }
    },
    {
      path: '/takeoutOrder',
      name: 'takeoutOrder',
      component: takeoutOrder,
      meta: {
        auth: true
      }
    },
    {
      path: '/refundDetail',
      name: 'refundDetail',
      component: refundDetail,
      meta: {
        auth: true
      }
    },
    {
      path: '/delicacies',
      name: 'delicacies',
      component: delicacies,
      meta: {
        keepAlive: true,
        // bMap: true
        script: ['baiduMap']
      }
    },
    {
      path: '/newStore',
      name: 'newStore',
      component: newStore,
      meta: {
        keepAlive: true,
        // bMap: true
        script: ['baiduMap']
      }
    },
    {
      path: '/shopping',
      name: 'shopping',
      component: shopping,
      meta: {
        keepAlive: true,
        // bMap: true
        script: ['baiduMap']
      }
    },
    {
      path: '/onlineBuy',
      name: 'onlineBuy',
      component: onlineBuy,
      meta: {
        keepAlive: true,
        script: ['baiduMap']
      }
    },
    {
      path: '/preferredOrder',
      name: 'preferredOrder',
      component: preferredOrder,
      meta: {
        auth: true
      }
    },
    {
      path: '/orderDetail',
      name: 'orderDetail',
      component: orderDetail,
      meta: {
        auth: true,
        script: ['baiduMap']
      }
    },
    {
      path: '/discount',
      name: 'discount',
      component: discount,
      meta: {
        auth: true
      }
    },
    {
      path: '/discountLose',
      name: 'discountLose',
      component: discountLose,
      meta: {
        auth: true
      }
    },
    {
      path: '/discountExplain',
      name: 'discountExplain',
      component: discountExplain
      // meta:{
      //   auth:true
      // }
    },
    {
      path: '/discountExplainSecond',
      name: 'discountExplainSecond',
      component: discountExplainSecond
      // meta:{
      //   auth:true
      // }
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: agreement
    },
    {
      path: '/myselfSeting',
      name: 'myselfSeting',
      component: myselfSeting,
      meta: {
        auth: true
      }
    },
    {
      path: '/myselfModif',
      name: 'myselfModif',
      component: myselfModif,
      meta: {
        auth: true
      }
    },
    {
      path: '/userLoveStore',
      name: 'userLoveStore',
      component: userLoveStore,
      meta: {
        auth: true
      }
    },
    {
      path: '/storeList',
      name: 'storeList',
      component: storeList,
      meta: {
        // bMap: true,
        // baidu
        // keepAlive: true,
        script: ['baiduMap']
      }
    },
    {
      path: '/storeCommentsList',
      name: 'storeCommentsList',
      component: storeCommentsList,
    },
    {
      path: '/storeDetail',
      name: 'storeDetail',
      component: storeDetail,
      meta: {
        // bMap: true,
        // baidu
        script: ['weixinSdk', 'baiduMap']
      }
    },
    {
      path: '/buffetDinner',
      name: 'buffetDinner',
      component: buffetDinner,
      meta: {
        // bMap: true,
        script: ['baiduMap'],
        keepAlive: true
      }
    },
    {
      path: '/buffetDinnerReserve',
      name: 'buffetDinnerReserve',
      component: buffetDinnerReserve
    },
    {
      path: '/cyberSource',
      name: 'cyberSource',
      component: cyberSource
    },
    {
      path: '/buffetDinnerCommit',
      name: 'buffetDinnerCommit',
      component: buffetDinnerCommit,
      meta: {
        auth: true
      }
    },
    {
      path: '/buffetPaySuccess',
      name: 'buffetPaySuccess',
      component: buffetPaySuccess
    },
    {
      path: '/groupOrderCommit',
      name: 'groupOrderCommit',
      component: groupOrderCommit,
      meta: {
        auth: true
      }
    },
    {
      path: '/groupDetail',
      name: 'groupDetail',
      component: groupDetail,
      meta: {
        // keepAlive: true,
        script: ['weixinSdk', 'baiduMap']
      }
    },
    {
      path: '/groupSubfield',
      name: 'groupSubfield',
      component: groupSubfield,
      meta: {
        // baidu
        // bMap: true,
        script: ['weixinSdk', 'baiduMap']
      }
    },
    {
      path: '/newSubfield',
      name: 'newSubfield',
      component: newSubfield,
      meta: {
        // baidu
        // bMap: true,
        script: ['weixinSdk', 'baiduMap']
      }
    },
    {
      path: '/groupPicDetail',
      name: 'groupPicDetail',
      component: groupPicDetail,
      meta: {
        script: ['weixinSdk']
      }
    },
    {
      path: '/groupCommentsList',
      name: 'groupCommentsList',
      component: groupCommentsList,
    },
    {
      path: '/paySuccess',
      name: 'paySuccess',
      component: paySuccess
    },
    {
      path: '/secKillList',
      name: 'secKillActivity',
      component: secKillActivity,
      meta: {
        script: ['weixinSdk']
      }
    },
    {
      path: '/buffetDinnerDetail',
      name: 'buffetDinnerDetail',
      component: buffetDinnerDetail,
      meta: {
        // baidu
        // bMap: true,
        script: ['weixinSdk', 'baiduMap']
      }
    },
    {
      path: '/buffetCommentsList',
      name: 'buffetCommentsList',
      component: buffetCommentsList
    },
    {
      path: '/buffetDinnerPicTextDetail',
      name: 'buffetDinnerPicTextDetail',
      component: buffetDinnerPicTextDetail,
      meta: {
        script: ['weixinSdk']
      }
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/takeoutSearch',
      name: 'takeoutSearch',
      component: takeoutSearch,
      meta: {
        // baidu
        // bMap: true,
        script: ['baiduMap']
      }
    },
    {
      path: '/searchPosition',
      name: 'searchPosition',
      component: searchPosition,
      meta: {
        // baidu
        // bMap: true,
        script: ['baiduMap']
      }
    },
    {
      path: '/preferredDetail',
      name: 'preferredDetail',
      component: preferredDetail,
      meta: {
        script: ['weixinSdk']
      }
    },
    {
      path: '/preferredList',
      name: 'preferredList',
      component: preferredList,
      meta: {
        script: ['weixinSdk'],
        keepAlive: true
      }
    },
    {
      path: '/GroupRefundDetail',
      name: 'GroupRefundDetail',
      component: GroupRefundDetail,
      meta: {
        auth: true
      }
    },
    {
      path: '/goRefund',
      name: 'goRefund',
      component: goRefund,
      meta: {
        auth: true
      }
    },
    {
      path: '/takeoutAllClazz',
      name: 'takeoutAllClazz',
      component: takeoutAllClazz
    },
    {
      path: '/takeoutSubList',
      name: 'takeoutSubList',
      component: takeoutSubList,
      meta: {
        script: ['baiduMap'],
        keepAlive: true
      }
    },
    {
      path: '/inviteGetRedPacket',
      name: 'inviteGetRedPacket',
      component: inviteGetRedPacket,
      meta: {
        auth: true,
        script: ['weixinSdk']
      }
    },
    {
      path: '/footballActive',
      name: 'footballActive',
      component: footballActive,
      meta: {
        // auth: true,
        script: ['weixinSdk']
      }
    },
    {
      path: '/football',
      name: 'football',
      component: football,
      meta: {
        // auth: true,
        script: ['weixinSdk']
      }
    },
    {
      path: '/activity',
      name: 'activity',
      component: activity,
      meta: {
        script: ['weixinSdk']
      }
    },
    {
      path: '/newSemester',
      name: 'newSemester',
      component: newSemester,
      meta: {
        script: ['weixinSdk']
      }
    }
  ]
})
