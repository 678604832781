/**
 * 定義環境變量env：test（測試），gray（灰度），prod（生產）
 */

let env = ''
if (process.env.VUE_APP_ENV == 'test') {
  env = 'test'
} else if (process.env.VUE_APP_ENV == 'uat') {
  env = 'uat'
} else if (process.env.VUE_APP_ENV == 'gray') {
  env = 'gray'
} else {
  env = 'prod'
}

const HOST = {
  test: 'https://test.aomi.mo',
  uat: 'https://test.aomi.mo/uat',
  gray: 'https://wap.aomi.mo/gray',
  prod: 'https://wap.aomi.mo'
}

export { env, HOST }
