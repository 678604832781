import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store/'
import VueHead from 'vue-head'
import { Tip, Title, Native } from '@/plugins/plugin'
import '@/utils/rem'
import { loadScripts } from '@/utils/util'
import { setStore } from '@/utils/setStore'
import { InfiniteScroll } from 'mint-ui'
import VueLazyload from 'vue-lazyload'
import defultProfile3x from '../public/defaultPicture1.png'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.use(VueLazyload, {
  preLoad: 1,
  error: defultProfile3x,
  loading: defultProfile3x,
  attempt: 3
})

// require ('alloylever')
// if ('addEventListener' in document) {
//   document.addEventListener('DOMContentLoaded', function() {
//     FastClick.attach(document.body)
//   }, false)
// }

// / gsg d
Vue.use(InfiniteScroll)
Vue.use(Tip)
Vue.use(Title)
Vue.use(Native)
Vue.use(VueHead)

Vue.config.productionTip = false
Vue.config.silent = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
// Vue的錯誤處理
Vue.config.errorHandler = function (err, vm, info) {
  console.log(vm)
  console.error('vue errorHandler：', err)
}

Vue.config.ignoredElements = [ // 忽略自定义元素标签抛出的报错
  'wx-open-launch-app',
  'wx-open-launch-weapp' 
];

// 初始化store
setStore()

// 組件登錄验证
router.beforeEach((to, from, next) => {
  store.commit('SHOW_INDCATOR', { show: true }) // 顯示頁面切換
  store.commit('TOGGLE_SEARCH', false) // 隱藏搜索頁面
  store.commit('SET_SEARCH_VALUE', '') // 清空頁面搜索框的值

  // 從首頁點擊秒殺進入詳情 後退不是回到首頁 是回到秒殺列表頁
  if (
    from.name == 'groupDetail' &&
    from.query.fromHome == 'true' &&
    to.name == 'home'
  ) {
    next({
      path: '/secKillList',
      query: {
        activityId: sessionStorage.getItem('activityId') || ''
      }
    })
  }
  document.body.style.position = 'static'
  // 路由異步加載js
  if (to.matched.some(toItem => toItem.meta.script)) {
    loadScripts(to.meta.script).then(() => {
      toRouter()
    })
  } else {
    toRouter()
  }

  function toRouter() {
    // 登錄驗證
    if (to.matched.some(toItem => toItem.meta.auth)) {
      console.log('user:', store.state.user)
      if (store.state.user) {
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    } else {
      next()
    }
  }
})

router.afterEach(to => {
  store.commit('SHOW_INDCATOR', false) // 關閉頁面切換
})

new Vue({
  router,
  store,
  render: h => h(App),
  components: { App }
}).$mount('#app')
