/**
 * Created by wfx on 2017/8/24.
 */
import {
  SHOW_INDCATOR,
  TOGGLE_SEARCH,
  SET_USER,
  DEL_USER,
  GET_GROUP_DETAIL,
  SET_SEARCH_VALUE,
  SET_IS_WEIXIN
} from './mutation-types'

export default {
  [SHOW_INDCATOR] (state, param) {
    state.indicator = {
      show: typeof param === 'object' ? param.show : param,
      text: typeof param === 'object' ? param.text : ''
    }
  },
  [TOGGLE_SEARCH] (state, value) {
    state.showSearchPage = value
  },
  [SET_USER] (state, user) {
    state.user = user
  },
  [DEL_USER] (state) {
    state.user = null
  },
  [GET_GROUP_DETAIL] (state, groupDetail) {
    state.groupPurchaseInfo = groupDetail
  },
  [SET_SEARCH_VALUE] (state, searchValue) {
    state.searchValue = searchValue
  },
  [SET_IS_WEIXIN] (state, value) {
    state.isWeiXin = value
  }
}
