/**
 * Created by wfx on 2017/8/24.
 */
import { getGroupDetails } from '../api/group'
export default {
  getGroupPurchaseInfo ({ commit }, params) {
    getGroupDetails(params).then(res => {
      commit('GET_GROUP_DETAIL', res)
    })
  }
}
