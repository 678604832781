import { Toast } from "mint-ui";
import native from "@aomi/old-native";
/**
 * 提示插件，在代碼裏通過this.$tip()調用
 * @param [string/object] 字符串就用默認的，對象就使用傳進來的配置
 */
export const Tip = {
  install: function(Vue, opt) {
    Vue.prototype.$tip = function(paramObj) {
      function appendMask(time) {
        let mask = document.createElement("div");
        mask.className = "transparent_mask";
        document.body.appendChild(mask);
        setTimeout(function() {
          document.body.removeChild(mask);
        }, time);
      }
      if (typeof paramObj === "string") {
        Toast({
          message: paramObj,
          position: "middle",
          duration: 3000,
        });
        appendMask(3000);
      } else {
        Toast({
          message: paramObj.message,
          position: paramObj.position ? paramObj.position : "middle",
          duration: paramObj.duration ? paramObj.duration : 3000,
        });
        appendMask(paramObj.duration ? paramObj.duration : 3000);
      }
    };
  },
};

/**
 * 更改window.title
 */
export const Title = {
  install: function(Vue, opt) {
    Vue.prototype.$title = function(title) {
      window.title = title;
    };
  },
};

export const Native = {
  install: function(Vue, opt) {
    Vue.prototype.$native = native;
  },
};
