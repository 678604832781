<template>
  <div class="indicator">
    <div class="indicator-wrapper"
         v-show="showIndicator">
      <span class="indicator-spin">
        <div class="spinner-snake"
             style="border-top-color: rgb(204, 204, 204); border-left-color: rgb(204, 204, 204); border-bottom-color: rgb(204, 204, 204); height: 32px; width: 32px;"></div>
      </span>
      <span class="indicator-text"
            v-if="indicatorText">{{indicatorText}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Indicator',
  data () {
    return {}
  },
  props: ['showIndicator', 'indicatorText'],
  mounted () {},
  created () {}
}
</script>

<style scoped lang='scss'>
@import '../assets/css/mixin.scss';
.indicator {
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.indicator-wrapper {
  padding: 15px 23px;
  top: 50%;
  left: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  z-index: 1000;
}
.indicator-spin {
  display: inline-block;
  text-align: center;
}
.spinner-snake {
  -webkit-animation: spinner-rotate 0.8s infinite linear;
  animation: spinner-rotate 0.8s infinite linear;
  border: 4px solid transparent;
  border-radius: 50%;
}
.indicator-text {
  display: block;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  font-size: 0.427rem /* 16/37.5 */;
}
@keyframes spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
