<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Indicator :showIndicator='indicator.show'
               :indicatorText='indicator.text'></Indicator>
  </div>
</template>

<script>
import Indicator from './components/Indicator.vue'
export default {
  name: 'app',
  components: {
    Indicator
  },
  computed: {
    indicator () {
      return this.$store.state.indicator
    }
  }
}
</script>
<style>
@import './assets/css/common.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
